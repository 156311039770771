import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import '../components/layout.css';


export default function BlogPost({ data }) {
  const post = data.markdownRemark
  let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid

  return (
    
    <div className="container">
      <Header />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h6>{post.frontmatter.date}</h6>
      <br></br>
      <br></br>
      <h1>{post.frontmatter.title}</h1>
      <Img fluid={featuredImgFluid} />
      <div className="code-container" dangerouslySetInnerHTML={{ __html: post.html }} />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query BlogQuery($slug: String!) { 
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "dddd MMMM Do, YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`